@import "../../styles/variables";


.footer {
  text-align: left;
  padding: 30px 20px;

  .logo {
    text-align: center;
  }

  p {
    color: #1D355E;
    padding: 8px 0px;
    font-size: 12px;
  }

  p.header {
    font-size: 16px;
    font-family: 'museoSans-bold';
    span {
      font-family: 'museoSans-regular';
    }
  }

  .logo {
    padding-top: 40px;
  }

  a {
    color: #1D355E;
    font-weight: 700;
  }

  @media only screen and (min-width: $small) {
    text-align: center;
    align-items: center;
    padding: 56px 50px;
  }
  .footerLink {
    margin-bottom: 12px;
  }
}
.link {
  border: none;
  background-color: transparent;
  font-size: 16px;
    font-family: 'museoSans-bold';
    color: #1D355E;
    text-decoration: underline;
    
}
