.popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    left: 0;
    z-index: 999;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
}

.popup:target {
    opacity: 1;
    visibility: visible;
}

.container {
    max-width: 550px;
    width: calc(100% - 32px);
    background-color: #fff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.25);
    padding: 15px 24px 24px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s 0.2s;
    z-index: 9999;
    border: 0.5px solid #1D355E;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
    h2 {
        font-family: 'museoSans-bold';
        color: #1D355E;
        font-size: 24px;
        line-height: 32px;
        
        text-align: left;
        margin-bottom: 16px;
        @media (min-width:992px) {
            font-size: 32px;
            line-height: 44px;
        }
    }
}

.popup:target + .container{
    opacity: 1;
    visibility: visible;
    transform: translate(-50%, -50%) scale(1);
}


.content{
    font-size: 16px;
    line-height: 26px;
    margin: 0 0 16px 0;
    display: block;
    color: #1D355E;
    margin-top: 10px;
    margin-bottom: 40px;
    text-align: left;
}
.button {
    font-size: 20px;
    line-height: 28px;
    display: block;
    background-color: #2D9ED7;
    color: #fff;
    padding: 16px 44px 16px 24px;
    text-decoration: none;
    position: relative;
    text-align: left;
    border: none;
    width: 100%;
    font-family: 'museoSans-semibold';
    &::after {
        content: ">";
        position: absolute;
        right: 24px;
        top: 50%;
    transform: translateY(-50%);
    }
    @media (min-width:992px) {
        font-size: 26px;
        line-height: 36px;
    }
}
.closeIcon {
    font-size: 24px;
    text-align: right;
    line-height: 20px;
    margin-right: -6px;
    span:hover{
        cursor: pointer;
    }
}