.highlight {
    margin-top: 34px;
    p {
        font-family: 'museoSans-semibold';
        font-size: 20px;
        line-height: 28px;
        color: #9A5EA1;
        margin-bottom: 40px !important;
        @media (min-width:992px) {
            font-size: 26px;
            line-height: 36px;
        }
    }
}
.topBorder {
    width: 100%;
    height: 8px;
    background: linear-gradient(270deg, #2D9ED7 0%, #9A5EA1 53.81%, #CD3787 103.31%);
    margin-bottom: 16px;
    
}