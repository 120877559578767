.twoColumnText {
    padding: 32px 16px;
    max-width: 1128px;
    margin: auto;
    sup {
        line-height: 0px;
    }
    @media (min-width:992px) {
        display: grid;
        padding: 80px 16px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 48px;
    }
    @media (min-width:1200px) {
        padding: 80px 0;
    }
    &.bp1 {
        padding-bottom: 1em;
    }
}
.firstText {
    font-family: 'museoSans-semibold';
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
    strong {
        font-family: 'museoSans-black';
    }
    @media (min-width:992px) {
        font-size: 26px;
        line-height: 36px;
        margin-bottom: 0;
    }
}

.secondText {
    font-size: 16px;
    line-height: 26px;
}