.mainMenu {
    padding: 24px 16px;
    position: fixed;
    top: 47px;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 9;
    transition: all 0.2s linear;
    box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.10);
    -webkit-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.10);
    -moz-box-shadow: 1px 1px 1px 1px rgba(0,0,0,0.10);
    @media (min-width: 992px) {
        padding: 48px 16px;
    }
    @media (min-width:1200px) {
        padding: 48px 0px;
    }
    h2 {
        font-family: 'museoSans-bold';
        font-size: 24px;
        line-height: 32px;
        padding-right: 60px;
        @media (min-width: 992px) {
            font-size: 32px;
            line-height: 44px;
            padding-right: 0px;
        }
    }
    .is-active{
        font-family: 'museoSans-bold';
    }
    
}
.container {
    max-width: 1128px;
    margin: auto;
    @media (min-width: 992px) {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
    h2 {
        color: #1D355E;
        @media (min-width: 992px) {
            grid-column: span 6 / span 6;
        }
        
    }
    .linkContainer {
        position: absolute;
        right: 0px;
        top: 0px;
        left: 0px;
        height: 50px;
        @media (min-width: 992px) {
            position: static;
            grid-column: span 6 / span 6;
            justify-self: end;
        }
        .nav {
            height: 0px;
            overflow: hidden;
            position: absolute;
            left: 0;
            right: 0px;
            top: 80px;
            background-color: #fff;
            transition: all 1s linear;
            @media (min-width: 992px) {
                overflow: auto;
                height: auto;
                position: static;
            }
            ul {
                padding: 20px;
                @media (min-width: 992px) {
                    padding: 0px;
                }
            }
        }   
    }
}

.checkbox {
    display: none;
}

.list {
    @media (min-width:992px) {
        li {
            
        }
    }
}
.item {
    list-style: none;
    @media (min-width:992px) {
        display: inline-block;
        margin-left: 32px;
    }
}
.link {
    color:#1D355E;
    font-size:20px;
    line-height: 46px;
    text-decoration: none;
    @media (min-width:992px) {
        font-size: 16px;
        line-height: 46px;
    }
}

.mainMenu {
    ul {

    }
    li {
        list-style: none;
        @media (min-width:992px) {
            display: inline-block;
            margin-left: 32px;
        }
        a {
            color:#1D355E;
            font-size:20px;
            line-height: 46px;
            text-decoration: none;
            @media (min-width:992px) {
                font-size: 16px;
                line-height: 46px;
            }
        }
        &:hover{
            font-family: 'museoSans-bold';
        }
    }
}


.button {
    background-color: #fff;
    width: 50px;
    height: 50px;
    cursor: pointer;
    text-align: center;
    position: absolute;
    display: inline-block;
    right: 16px;
    top: 34px;
    @media (min-width:435px) {
        top: 17px;
    }
    @media (min-width:992px) {
        display: none;
    }
  }

.icon {
    position: relative;
    top: 20px;
}
.icon,
.icon::after,
.icon::before {
  width: 36px;
  height: 3px;
  content: "";
  display: inline-block;
  background-color: #333041;
  transition: all 0.1s linear;
}
.icon::before,
.icon::after {
  position: absolute;
  left: 0;
}
.icon::before {
  top: -10px;
}
.icon::after {
  top: 10px;
}

.checkbox:checked + .button .icon {
    background-color: transparent;
  }
.checkbox:checked + .button .icon::before {
    top: 0;
    transform: rotate(135deg);
}
.checkbox:checked + .button .icon::after {
    top: 0px;
    transform: rotate(-135deg);
}

.checkbox:checked ~ .nav {
    height: auto;
    overflow: auto;
    @media (max-width:992px) {
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.10);
        -webkit-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.10);
        -moz-box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.10);
    }
}