@import "../../styles/variables";


.sectionBlue {
  background: #2D9ED7;
 > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 32px 16px;
  gap: 24px;
  width: auto;
  height: auto;


  flex: none;
  order: 0;
  flex-grow: 0;

  .title {
    color: #FFFFFF;
    font-size: 20px;
    font-family: 'museoSans-bold';
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 24px;

    @media (min-width: $small) {
      font-size: 26px;
      line-height: 36px;
    }
  }

  .button {
    color: #2D9ED7;
    background: #FFFFFF;
    // width: 450px;
    text-decoration: none;
    border: none;
    font-family: 'museoSans-semibold';
    font-weight: 600;
    padding: 10px 44px 10px 24px;
    position: relative;
    font-size: 20px;
    // line-height: 22px;
    transition: all 0.1s linear;
    text-align: left;
    z-index: 8;
    @media (min-width:992px) {
      font-size: 26px;
      line-height: 36px;
    }
    &::after {
      content: ">";
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.04);
    }
  }
}
 

  @media (min-width: $small) {
    > div {
      display: grid;
      padding: 56px 16px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      max-width: 1128px;
      gap: 48px;
      margin: auto;
      align-items: center;
    }
  }
  @media (min-width:1200px) {
    > div {
      padding: 16px 0;
    }
  }
}