
// CSS reset
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'museoSans-regular';
    color: #1D355E;
    padding-top: 159px;
    @media (min-width:442px) {
        padding-top: 124px;
    }
    @media (min-width:992px) {
        padding-top: 193px;
    }

}

button {
    &:hover {
        cursor: pointer;
    }
}

.no-wrap {
    text-wrap: avoid;
    white-space: nowrap;
}

a[href*="tel:"] {
    cursor: none;
    pointer-events: none;
    text-decoration: none;
    display: inline-block;
}
@media only screen and (max-width: 992px) {
    a[href*="tel:"] {
        cursor:default;
        pointer-events: auto;
    }
}


.m-b-0 {
    margin-bottom: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}