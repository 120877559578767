.heroBanner {
    background: linear-gradient(270deg, #2D9ED7 0%, #9A5EA1 53.81%, #CD3787 103.31%);
    padding: 32px 16px;
    @media (min-width: 992px) {
        padding: 56px 0px;
    }
    h1 {
        font-family: 'museoSans-bold';
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        letter-spacing: 0.005em;
        color: #fff;
        @media (min-width: 992px) {
            font-size: 57px;
            line-height: 64px;
        }
    }
}