
@font-face {
    font-family: 'museoSans-regular';
    src: url('../fonts/museoSans/MuseoSans-300.otf');
}
@font-face {
    font-family: 'museoSans-semibold';
    src: url('../fonts/museoSans/MuseoSans-500.otf');
}
@font-face {
    font-family: 'museoSans-bold';
    src: url('../fonts/museoSans/MuseoSans-700.otf');
}
@font-face {
    font-family: 'museoSans-black';
    src: url('../fonts/museoSans/MuseoSans-900.otf');
}

strong {
    font-family: 'museoSans-bold';
}
.indent {
    margin-left: 0px;
    line-height: 130%;
    display: block;
    text-indent: -5px;
}

.is-active {
    font-family: 'museoSans-bold';
}

