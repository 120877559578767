.container {
    max-width:1128px;
    margin: auto;
}

.small-menu {
    @media (min-width:992px) {
        padding: 28px 16px;
    }
}

// for exit website modal
.modal-container {
    transition: all 0.1s linear;    
}
.modal-close {
    .modal-container {
        opacity: 0;
        visibility: hidden;
        .popup {
            opacity: 0;
            visibility: hidden;
        }
        .exit-container {
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, -50%) scale(0.25);
        }
    }
}
.modal-open {
    .modal-container {
        opacity: 1;
        visibility: visible;
        .popup {
            opacity: 1;
            visibility: visible;
        }
        .exit-container {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
            max-width: 550px;
        }
        
    }
}

// for going to HCP modal
.modalswitch-container {
    transition: all 0.1s linear;    
}
.modal-switch-close {
    .modal-switch-container {
        opacity: 0;
        visibility: hidden;
        .switch-popup {
            opacity: 0;
            visibility: hidden;
        }
        .switch-container {
            opacity: 0;
            visibility: hidden;
            transform: translate(-50%, -50%) scale(0.25);
        }
    }
}
.modal-switch-open {
    .modal-switch-container {
        opacity: 1;
        visibility: visible;
        .switch-popup {
            opacity: 1;
            visibility: visible;
        }
        .switch-container {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%) scale(1);
            max-width: 550px;
        }
        
    }
}