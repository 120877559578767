.topBanner {
    background-color: #1D355E;
    color:#fff;
    padding: 16px;
    font-size: 12px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    @media (min-width: 768px) {
        text-align: right;
    }
    @media (min-width: 1200) {
        padding-left: 0;
        padding-right: 0;
    }
        font-size: 13px;
    a {
        color:#fff;
    }
}
.link {
    border: none;
    background-color: transparent;
    color: #fff;
    font-size: 13px;
    font-family: 'museoSans-regular';
    text-decoration: underline;
    &:hover {
        cursor: pointer;
    }
}