.section {
    max-width: 1128px;
    margin: auto;
    padding: 53px 16px 32px;
    position: relative;
    top: 125px;

    @media (min-width:992px) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 48px;
        align-items: center;
    }
    @media (min-width:1200px) {
        padding: 88px 0px;
        top: 95px;

    }
    h3 {
        font-family: 'museoSans-bold';
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
        @media (min-width:992px) {
            font-size: 32px;
            line-height: 44px;
        }
    }
    &.leftImg {
        .imageContainer {
            @media (min-width:992px) {
                order: 0;
            }
            
        }
    }
    &.no-padding {
        padding: 5px 16px 37px;
        top: 0;
    }
    &.pb0 {
        padding-bottom: 0;
    }
    &.one-column {
        @media (min-width:992px) {
            display: block;
        }
    }
    &.pt1 {
        padding-top: 1em;
    }

    .pb-40 {
        padding-bottom: 15px;
    }
}

.section-anchor {
    .no-padding {
        padding: 0px;
        @media (max-width:992px) {
        padding-left: 15px;
        padding-right: 15px
        }
    }
}
.textContainer {
    font-size: 16px;
    line-height: 26px;
    p {
        margin-bottom: 16px;
        line-height: 
        a {
            color: #9A5EA1;
            text-decoration: underline;
            font-family: 'museoSans-bold';
        }
    }

    sup {
        line-height: 0px;
    }
}

.imageContainer {
    text-align: center;
    @media (min-width:992px) {
        order: 2;
    }
    img {
        width: 100%;
        height: auto;
        max-width: 400px;
        margin-bottom: 77px;
        @media (min-width:992px) {
            margin-bottom: 0;
        }
        @media (max-width:992px) {
            max-width: 85%;
        
        &.icon {
            width: 60%;
            margin-bottom: 20px;
        }
        }
    }
}
.link {
    color: #9A5EA1;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-size: 16px;
    line-height: 26px;
    font-family: 'museoSans-bold';
    &:hover {
        cursor: pointer;
    }
}
.section-anchor:not(.no-padding) {
    position: relative;
    width: 100%;
    top: -125px;
    @media (min-width:1200px) {
        top: -95px;
    }
}